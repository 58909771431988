import React, {useEffect, useState, useMemo} from 'react';
import { LegacyStack, ChoiceList, TextField, Text } from "@shopify/polaris";
import {DISCOUNT_TYPES} from '../../../../../constants/constants';
import DiscountWarningBanner from '../discountWarningBanner/discountWarningBanner';
import update from 'immutability-helper';


const DiscountCart = (props) => {
  const {state, setState, currency, currencySymbol, isAi = true, discountType = 'compareAtPrice', index = 0, isNewDiscountVersion = false} = props;
  const [selectedType, setSelectedType] = useState('percentage');
  useEffect(() => {
    const newValue = Array.isArray(state.product) && state.product.length > 0 && !isAi
      ? !state.product[index][discountType] ? 'fixedAmount' : (state.product[index][discountType]?.sym === currencySymbol ? 'fixedAmount' : 'percentage')
      : !state[discountType] ? 'fixedAmount' : (state[discountType]?.sym === currencySymbol ? 'fixedAmount' : 'percentage');
      setSelectedType(newValue);
  }, [state, discountType, setSelectedType, index]);

  const handleChangeDiscount = (value) => {
    const newVal = 'percentage' === selectedType && value > 100 ? 100 : value;
    if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
      setState(update(state, {
        [discountType]: {$set: {
          sym: 'fixedAmount' === selectedType ? currencySymbol : '%',
          value: newVal
        }},
        product: {
          [index]: {[discountType]: {$set: {
            sym: 'fixedAmount' === selectedType ? currencySymbol : '%',
            value: newVal
        }}}}
      }))
    } else {
      setState(update(state, {
        [discountType]: {$set: {
          sym: 'fixedAmount' === selectedType ? currencySymbol : '%',
          value: newVal
        }}
      }))
    }
  }

  const handleDiscountTypeChange = (type) => {
    const discount = {sym: type === 'percentage' ? '%' : currencySymbol, value: ''}
      if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
        setState(update(state, {
          [discountType]: {$set: discount},
          product: {[index]: {[discountType]: {$set: discount}}}
        }))
      } else {
        if ((type === 'percentage' && "%" === state[discountType]?.discount?.sym) || (type === 'fixedAmount' && currencySymbol === state[discountType]?.discount?.sym) ) return;
        setState(update(state, {[discountType]: {$set: discount}}))
      }
      setSelectedType(type);
  }

  const discountValue = useMemo(() => {
    if (Array.isArray(state.product) && state.product.length > 0 && !isAi) {
      return state.product[index][discountType] && state.product[index][discountType]?.sym
        ? String(state.product[index][discountType].value)
        : state.product[index][discountType] || ''
    } else {
      return state[discountType] && state[discountType]?.sym
        ? String(state[discountType].value)
        : state[discountType] || ''
    }
  }, [state]);

  return (
    <div className='discount-step'>
      {
        !isNewDiscountVersion &&
          <Text variant="headingSm" as="h3">
            {
              discountType === 'compareAtPrice' ? 'Compare at price' : 'Discount'
            }
          </Text>
      }
      <LegacyStack vertical={true}>
                <ChoiceList
                  title="Type"
                  choices={DISCOUNT_TYPES}
                  selected={selectedType}
                  onChange={(value) => handleDiscountTypeChange(value[0])}
                />
                <TextField
                  onChange={(val) => handleChangeDiscount(val)}
                  value={discountValue}
                  min={0}
                  max={"percentage" === selectedType ? 100 : null}
                  type={'text'}
                  suffix={"fixedAmount" === selectedType ? currency : '%'}
                />
      </LegacyStack>
      {
        ((state[discountType] && state[discountType]?.value) || isNewDiscountVersion) &&
          <DiscountWarningBanner isAi={isAi}/>
      }
    </div>
  );
};

export default DiscountCart;